<template>
  <div class="home-class">
    <div class="search-div">
      <el-input placeholder="请输入搜索内容" v-model="search">
        <el-select
          v-model="searchtype"
          slot="prepend"
          placeholder="请选择"
          class="select-class"
        >
          <el-option
            v-for="(item, index) in typelist"
            :key="'type' + index"
            :label="item.name"
            :value="item.sign"
          ></el-option>
        </el-select>
        <el-button
          slot="append"
          icon="el-icon-search"
          @click="getnow()"
        ></el-button>
      </el-input>
    </div>
    <div class="home-body">
      <div
        class="card-class"
        v-for="(item, key) in searchdata"
        :key="'' + key"
        @click="gotosee(item)"
      >
        <div class="card-text-public title">{{ item.title }}</div>
        <div class="card-text-public context">
          {{ item.upcontent }}
        </div>
        <div class="card-text-public other">
          {{ "浏览量：" + item.browse + " | 点赞量：" + item.number }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      searchtype: "",
      searchdata: [],
      typelist: [],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.$axios
        .get(
          "/appealapi/getall2user.php?u=" + sessionStorage.getItem("username")
        )
        .then((res) => {
          this.searchdata = res.data;
        });
      this.$axios.get("/appealapi/gettype.php").then((res) => {
        this.typelist = res.data;
        this.typelist.push({ name: "全部", sign: "all" });
      });
    },
    gotosee(item) {
      this.$router.push({ path: "/details", query: { id: item.id } });
    },
    getnow() {
      this.$axios
        .get(
          "/appealapi/getusernow.php?t=" +
            (this.searchtype ? this.searchtype : "all") +
            "&n=" +
            this.search +
            "&u=" +
            sessionStorage.getItem("username")
        )
        .then((res) => {
          this.searchdata = res.data;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.home-class {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  .search-div {
    width: 96%;
    margin: 10px 0px;
    .select-class {
      width: 100px;
    }
  }
  .home-body {
    height: calc(100% - 80px);
    width: 96%;
    margin: 10px 0px;
    .card-class {
      width: 100%;
      border-bottom: 1px solid#EEEEEE;
    }
    .title {
      font-size: 17px;
    }
    .context {
      font-size: 13px;
      color: #a9a9a9;
    }
    .other {
      font-size: 13px;
      color: #d6d6d6;
    }
  }
}
</style>